import GoogleMapReact from 'google-map-react';


export const Contact = (props) => {

  const defaultProps = {
    center: {
      lat: 39.868153,
      lng: 32.691176
    },
    zoom: 16
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Iletişim</h2>
                <div className="map-container" style={{height: '25vh', width: '100%'}}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDMU30-CGwX1NCiJxFN9MLoCS-jW2EsK9E" }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                  >
                 
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Iletişim Bilgilerimiz</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Adres
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefon
                </span>{" "}
                {props.data ? props.data.phone.map((x, index) => (
                  <span key={index}>{x}</span>
                )) : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 all rights reserved, BOZKURT HUKUK & DANIŞMANLIK BÜROSU
          </p>
        </div>
      </div>
    </div>
  );
};
