import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <div className="d-flex flex-row">
            <div className="col-xs-6 col-md-2">
              <img src="img/logo_small-50.jpg" className="img-responsive page-scroll" href="#page-top" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <span>
                Bozkurt Hukuk & Danışmanlık
              </span>
            </div>
          </div>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll">
                Hakkımızda
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Hizmetlerimiz
              </a>
            </li>
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Müşterilerimiz
              </a>
            </li> */}
            <li>
              <a href="#highway" className="page-scroll">
                Otoyol Borçları
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Iletişim
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
