import React from "react";

export const Highway = (props) => {
  return (
    <div id="highway" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Otoyol Borçları</h2>
        </div>
        <div id="row" className="d-flex justify-content-center align-items-center flex-wrap">
          <div className="col-md-8 col-md-offset-2">
          <p> Değerli ziyaretçilerimiz, </p>
            <p>
              Tarafınıza tebliğ olan ödeme emirleri ve hakkınızda açılmış bulunan icra takiplerine ilişkin 0850 241 03 17 
              numaralı cep telefonunu mesai saatleri içerisinde arayarak bilgi edinebilirsiniz. Hafta içi 09:00-18:00 saatleri arasında tarafımıza ulaşabilirsiniz.
            </p>
            <p>
              ERG OTOYOL YATIRIM VE İŞLETME ANONİM ŞİRKETİ’ne ait icra takiplerin kaynaklanan borçlarınızı, 
            </p>
            <a target='_blank' href="https://www.ankaranigdeotoyolu.com/ihlalli-gecis-sorgulama-ve-online-odeme" rel="noreferrer"> 
              www.ankaranigdeotoyolu.com/ihlalli-gecis-sorgulama-ve-online-odeme
            </a>
            <p>
              sayfası üzerinden araç plakanızı rakam ve harfler arasında boşluk bulunmaksızın girmek suretiyle veya TC Kimlik numaranız ile sorguladıktan sonra borcunuzu aynı sayfa üzerinden ödeyebilirsiniz.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
